<template>
  <div class="row q-pt-lg q-px-lg">
    <!-- Notif Dialog -->
    <q-dialog
      v-model="dialog.show"
    >
      <q-card style="width: 300px">
        <q-card-section>
          <div>{{ dialog.message }}</div>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn flat size="sm" label="Close" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <!-- Form Dialog -->
    <q-dialog
      v-model="dialogForm.show"
    >
      <form-skill
        :mode="dialogForm.mode"
        :data="dialogForm.data"
        @onCancel="dialogForm.show = false"
        @postSubmit="dialogForm.show = false"
      />
    </q-dialog>
    <!-- Dialog Confirm -->
    <dialog-confirm v-model="dialogConfirm.show" :title="dialogConfirm.title" :body="dialogConfirm.body" @onYes="onDelete" />
    <!-- Data Table -->
    <q-table
      class="sticky-action full-width"
      title="Skills"
      :rows="rows"
      :columns="columns"
      :filter="filter"
      row-key="id"
    >
      <template #top-left>
        <q-btn color="primary" size="sm" @click="onCreate">New</q-btn>
        <span class="text-h6 q-ml-sm">Skills</span>
      </template>
      <template #top-right>
        <q-input dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template #body-cell-action="{ row }">
        <td>
          <q-btn-group>
            <q-btn color="secondary" size="xs" round icon="edit" @click="onSelectRow('edit', row)">
              <q-tooltip>
                Edit
              </q-tooltip>
            </q-btn>
            <q-btn color="red" size="xs" round icon="delete" @click="onSelectRow('delete', row)">
              <q-tooltip>
                Delete
              </q-tooltip>
            </q-btn>
          </q-btn-group>
        </td>
      </template>
    </q-table>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed, getCurrentInstance } from '@vue/runtime-core'
import { formatDate, capitalize } from '../utils/functions'
import formSkill from '../components/formSkill.vue'

export default {
  components: { formSkill },
  setup () {
    const instance = getCurrentInstance()
    const self = instance.appContext.config.globalProperties
    const store = useStore()

    const dialog = ref({
      show: false,
      message: ''
    })

    const dialogForm = ref({
      show: false,
      mode: 'new',
      data: ref({})
    })

    const dialogConfirm = ref({
      show: false,
      title: 'Wait',
      body: 'This can\'t be undone! Proceed?'
    })

    const filter = ref('')

    const columns = ref([
      { name: 'action', label: '', align: 'left', field: '' },
      { name: 'name', label: 'Name', align: 'left', field: 'name', sortable: true },
      { name: 'type', label: 'Type', align: 'left', field: 'type', sortable: true, format: val => capitalize(val) },
      { name: 'score', label: 'Score', align: 'left', field: 'score', sortable: true },
      { name: 'createdAt', label: 'Created At', field: 'createdAt', sortable: true, format: val => formatDate(val, 'DD-MMM-YYYY HH:mm') },
      { name: 'updatedAt', label: 'Updated At', field: 'updatedAt', sortable: true, format: val => formatDate(val, 'DD-MMM-YYYY HH:mm') }
    ])

    const rows = computed(() => store.state.skills)

    const selectedRow = ref({})

    const onSelectRow = async (mode, selected) => {
      selectedRow.value = selected
      if (mode === 'edit') {
        dialogForm.value.show = true
        dialogForm.value.mode = mode
        dialogForm.value.data = selected
      } else {
        dialogConfirm.value.show = true
      }
    }

    const onDelete = async () => {
      await self.$api.skill.delete(selectedRow.value?.id)
      await store.dispatch('setProfileFull')
      dialogConfirm.value.show = false
      dialog.value.show = true
      dialog.value.message = 'Delete skill success'
    }

    const onCreate = () => {
      dialogForm.value.show = true
      dialogForm.value.mode = 'new'
      dialogForm.value.data = {
        type: '',
        score: 0,
        name: ''
      }
    }

    return {
      columns,
      rows,
      onSelectRow,
      dialog,
      filter,
      dialogForm,
      onCreate,
      dialogConfirm,
      onDelete
    }
  }
}
</script>
